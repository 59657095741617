import { inject }                    from 'aurelia-framework';
import { PLATFORM }                  from 'aurelia-pal';
import { BaseFormViewModel }         from 'base-form-view-model';
import { FormSchema }                from 'modules/map/mat-app-proposals/form-schema';
import { MAPFilesRepository }        from 'modules/map/mat-app-proposals/services/files-repository';
import { MatAppProposalsRepository } from 'modules/map/mat-app-proposals/services/repository';
import { AppContainer }              from 'resources/services/app-container';

@inject(AppContainer, MatAppProposalsRepository, FormSchema, MAPFilesRepository)
export class EditMatAppProposal extends BaseFormViewModel {

    headerTitle = 'form.title.edit-record';
    formId      = 'map.mat-app-proposals.edition-form';

    alert  = {};
    model  = {};
    schema = {};

    edit = true;

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param formSchema
     * @param filesRepository
     */
    constructor(appContainer, repository, formSchema, filesRepository) {
        super(appContainer);

        this.repository      = repository;
        this.formSchema      = formSchema;
        this.filesRepository = filesRepository;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return this.redirectToSelectLotIntervention('map.mat-app-proposals.index') && super.can([
            'map.mat-app-proposals.manage',
            'map.mat-app-proposals.edit',
        ]);
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return PLATFORM.moduleName('modules/map/mat-app-proposals/form.html');
    }

    /**
     * Handles activate event
     *
     * @param params
     *
     * @returns {Promise.<*>|Promise}
     */
    async activate(params) {
        super.activate();
        this.initialModel = this.formSchema.model();
        this.model        = this.formSchema.model();

        return this.fetchData(params).then(() => {

            if (this.appContainer.authenticatedUser.isLotClosed || this.model.is_validated) {
                return this.redirectToRoute({
                    name:   'map.mat-app-proposals.view',
                    params: params || {},
                });
            }

            this.schema        = this.formSchema.schema(this);
            this.globalSchema  = this.formSchema.globalSchema(this);
            this.lotInfoSchema = this.formSchema.lotInfoSchema(this);
        });
    }

    /**
     * Submits view form
     *
     * @returns {Promise}
     */
    async submit() {
        this.alert = null;

        this.repository
            .update(this.model.id, this.model)
            .then((response) => {
                this.handleResponse(response, 'map.mat-app-proposals.index');
                this.initialModel.assign(this.model);
            })
            .catch((error) => console.error(error));
    }

    /**
     * Fetches data from server
     *
     * @param params
     *
     * @returns {Promise.<*>|Promise}
     */
    async fetchData(params) {
        const response = await this.repository.find(params.id);
        this.initialModel.assign(response);
        this.model.assign(response);
    }
}
