import { DialogService }              from 'aurelia-dialog';
import { bindable, inject }           from 'aurelia-framework';
import { I18N }                       from 'aurelia-i18n';
import { PLATFORM }                   from 'aurelia-pal';
import { BaseListViewModel }          from 'base-list-view-model';
import { ListFilesModal }             from 'modules/administration/files/index-modal';
import { LotInterventionsRepository } from 'modules/management/concessions/concessions-tree/lot-interventions/services/repository';
import { ReclassifyManufacturers }    from 'modules/map/management/manufacturers/reclassify';
import { ReclassifySuppliers }        from 'modules/map/management/suppliers/reclassify';
import { FilterFormSchema }           from 'modules/map/mat-app-proposals/filter-form-schema';
import { MAPFilesRepository }         from 'modules/map/mat-app-proposals/services/files-repository';
import { InfoDialog }                 from 'resources/elements/html-elements/dialogs/info-dialog';
import { AppContainer }               from 'resources/services/app-container';
import { Downloader }                 from 'resources/services/downloader';
import { MatAppProposalsRepository }  from './services/repository';

@inject(AppContainer, MatAppProposalsRepository, I18N, Downloader, FilterFormSchema, DialogService, LotInterventionsRepository, MAPFilesRepository)
export class ListMatAppProposals extends BaseListViewModel {

    listingId      = 'mat-app-proposals-listing';
    ignoredFilters = [];

    @bindable headerTitle    = 'listing.map.mat-app-proposals';
    @bindable newRecordRoute = 'map.mat-app-proposals.create';
    @bindable repository;
    @bindable datatable;

    @bindable fileSettings = {
        viewModel:               null,
        relatableModel:          null,
        relatableModelNameField: 'map_number',
        relatableRepository:     null,
        allowedMimeTypes:        null,
        hideName:                true,
    };

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param i18N
     * @param downloader
     * @param filterFormSchema
     * @param dialogService
     * @param lotInterventionsRepository
     * @param mAPFilesRepository
     */
    constructor(appContainer, repository, i18N, downloader, filterFormSchema, dialogService, lotInterventionsRepository, mAPFilesRepository) {
        super(appContainer, lotInterventionsRepository);

        this.repository         = repository;
        this.i18N               = i18N;
        this.downloader         = downloader;
        this.filterFormSchema   = filterFormSchema;
        this.dialogService      = dialogService;
        this.mAPFilesRepository = mAPFilesRepository;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return this.appContainer.globalConfig.defaultListView;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return this.redirectToSelectLotIntervention('map.mat-app-proposals.index') && super.can([
            'map.mat-app-proposals.manage',
            'map.mat-app-proposals.view',
        ]);
    }

    /**
     * Handles activate event
     */
    async activate() {
        super.activate();

        if (this.appContainer.authenticatedUser.isLotClosed) {
            this.newRecordRoute = null;
        }

        this.defineDatatable();

        this.filterModel  = this.filterFormSchema.model(this);
        this.filterSchema = await this.filterFormSchema.schema(this);

        // Default filter by the current lot_intervention_id
        if (!this.filterModel.lot_intervention_id) {
            this.selectedLotInformation          = this.appContainer.authenticatedUser.getAllSelectedLotInformation();
            this.filterModel.lot_intervention_id = this.selectedLotInformation.lot_intervention_id;
        }

        this.fileSettings.relatableRepository = this.mAPFilesRepository;
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            repository:         this.repository,
            show:               {
                action:  (row) => this.appContainer.router.navigateToRoute('map.mat-app-proposals.view', { id: row.id }),
                visible: (row) => this.appContainer.authenticatedUser.can(['map.mat-app-proposals.manage', 'map.mat-app-proposals.view']),
            },
            edit:               {
                action:  (row) => this.appContainer.router.navigateToRoute('map.mat-app-proposals.edit', { id: row.id }),
                visible: (row) => !row.is_validated
                    && this.appContainer.authenticatedUser.can(['map.mat-app-proposals.manage', 'map.mat-app-proposals.edit']),
            },
            destroy:            {
                action:  (row) => this.repository.destroy(row.id),
                visible: (row) => this.appContainer.authenticatedUser.can(['map.mat-app-proposals.manage', 'map.mat-app-proposals.delete']) && !row.is_validated,
            },
            actionsContextMenu: true,
            options:            [
                {
                    label:   'form.button.export-to-excel',
                    icon:    'icon-file-excel',
                    action:  () => this.exportListing(this.i18N.tr('form.field.mat-app-proposals'), !this.datatable.instance.selectedRows.length ? null : this.datatable.instance.selectedRows),
                    visible: (row) => this.appContainer.authenticatedUser.can(['map.mat-app-proposals.manage', 'map.mat-app-proposals.view']),
                },
                {
                    label:   'form.button.export-zip',
                    icon:    'icon-file-zip',
                    action:  () => this.exportZip(),
                    visible: () => this.appContainer.authenticatedUser.can(['map.mat-app-proposals.manage', 'map.mat-app-proposals.view']),
                },
                {
                    label:  'form.button.reclassify-manufacturers',
                    icon:   'icon-office',
                    action: () => this.ensureSelectedRows(() => this.viewReclassify('manufacturer', this.datatable.instance.selectedRows)),
                    // Check for regular permissions, and - if Lot is closed, user must be Highway Operator
                    visible: () => this.appContainer.authenticatedUser.can(['map.management.menu.access']) && this.appContainer.authenticatedUser.can([
                        'map.management.suppliers.manage',
                        'map.management.suppliers.view',
                        'map.management.suppliers.edit',
                    ]) && !(this.appContainer.authenticatedUser.isLotClosed && !this.appContainer.authenticatedUser.isHighwayOperator),
                },
                {
                    label:  'form.button.reclassify-suppliers',
                    icon:   'icon-truck',
                    action: () => this.ensureSelectedRows(() => this.viewReclassify('supplier', this.datatable.instance.selectedRows)),
                    // Idem - if Lot is closed, user must be Highway Operator
                    visible: () => this.appContainer.authenticatedUser.can(['map.management.menu.access']) && this.appContainer.authenticatedUser.can([
                        'map.management.manufacturers.manage',
                        'map.management.manufacturers.view',
                        'map.management.manufacturers.edit',
                    ]) && !(this.appContainer.authenticatedUser.isLotClosed && !this.appContainer.authenticatedUser.isHighwayOperator),
                },
                {
                    label:   'form.button.validate-selected',
                    icon:    'icon-checkmark3',
                    action:  () => this.ensureSelectedRows(() => {
                        this.callThenResponse(this.repository.validateSelected(this.datatable.instance.selectedRows));
                    }),
                    visible: () => this.appContainer.authenticatedUser.can(['map.mat-app-proposals.manage', 'map.mat-app-proposals.validate']),
                },
                {
                    label:   'form.button.invalidate-selected',
                    icon:    'icon-cross',
                    action:  () => this.ensureSelectedRows(() => {
                        this.callThenResponse(this.repository.invalidateSelected(this.datatable.instance.selectedRows));
                    }),
                    visible: () => this.appContainer.authenticatedUser.can(['map.mat-app-proposals.manage', 'map.mat-app-proposals.invalidate']),
                },
            ],
            actions:            [
                {
                    icon:    'icon-cross',
                    action:  (row) => this.callThenResponse(this.repository.invalidate(row.id)),
                    tooltip: 'form.button.invalidate',
                    visible: (row) => row.validatable && this.appContainer.authenticatedUser.can(['map.mat-app-proposals.manage', 'map.mat-app-proposals.invalidate'])
                        && row.is_validated && this.appContainer.authenticatedUser.inSameLotIntervention(row.lot_intervention_id),
                },
                {
                    icon:    'icon-checkmark3',
                    action:  (row) => this.callThenResponse(this.repository.validate(row.id)),
                    tooltip: 'form.button.validate',
                    visible: (row) => row.validatable && this.appContainer.authenticatedUser.can(['map.mat-app-proposals.manage', 'map.mat-app-proposals.validate'])
                        && !row.is_validated && this.appContainer.authenticatedUser.inSameLotIntervention(row.lot_intervention_id),
                },
                {
                    icon:    'icon-copy3',
                    action:  (row) => this.copyToAnotherLot(row.id, row.lot_intervention_id, 'map.mat-app-proposals.create'),
                    tooltip: 'form.button.duplicate',
                    visible: (row) => this.appContainer.authenticatedUser.can(['map.mat-app-proposals.manage', 'map.mat-app-proposals.create'])
                        && row.is_lot_intervention_related,
                },
                {
                    icon:    'icon-attachment',
                    action:  (row) => this.doShowFiles(row),
                    tooltip: 'form.button.files',
                    visible: (row) => (row.files && row.files.length > 0) && this.appContainer.authenticatedUser.can([
                        'map.mat-app-proposals.manage',
                        row.is_validated ? 'map.mat-app-proposals.view' : 'map.mat-app-proposals.edit',
                    ]),
                },
            ],
            selectable:
                                true,
            destroySelected:
                                true,
            sorting:
                                [
                                    {
                                        column:    0,
                                        direction: 'desc',
                                    },
                                    {
                                        column:    5,
                                        direction: 'desc',
                                    },
                                ],
            columns:
                                [
                                    {
                                        data:  'internal_order',
                                        name:  'mat_app_proposals.internal_order',
                                        title: 'form.field.internal-order',
                                    },
                                    {
                                        data:  'concession',
                                        name:  'concession_translations.description',
                                        title: 'form.field.concession',
                                    },
                                    {
                                        data:  'highway_designation',
                                        name:  'highway_translations.designation',
                                        title: 'form.field.highway',
                                    },
                                    {
                                        data:  'intervention_type_name',
                                        name:  'intervention_type_translations.designation',
                                        title: 'form.field.intervention-type',
                                    },
                                    {
                                        data:  'lot',
                                        name:  'lot_intervention_translations.designation',
                                        title: 'form.field.lot',
                                    },
                                    {
                                        data:  'map_number',
                                        name:  'mat_app_proposals.map_number',
                                        title: 'form.field.map-number',
                                    },
                                    {
                                        data:       'categories',
                                        name:       'mat_app_proposals.categories',
                                        title:      'form.field.categories',
                                        searchable: false,
                                        orderable:  false,
                                        type:       'processed',
                                        processor:  (row) => this.joinArrayInList(row, 'categories'),
                                    },
                                    {
                                        data:  'material_type_description',
                                        name:  'mat_app_proposals.material_type_description',
                                        title: 'form.field.description',
                                    },
                                    {
                                        data:  'use_locations',
                                        name:  'mat_app_proposals.use_locations',
                                        title: 'form.field.use-locations',
                                    },
                                    {
                                        data:  'supplier_name',
                                        name:  'supplier_translations.name',
                                        title: 'form.field.supplier',
                                    },
                                    {
                                        data:  'manufacturer_name',
                                        name:  'manufacturer_translations.name',
                                        title: 'form.field.manufacturer',
                                    },
                                    {
                                        data:  'other_supplier',
                                        name:  'mat_app_proposals.other_supplier',
                                        title: 'form.field.other-supplier',
                                    },
                                    {
                                        data:  'other_manufacturer',
                                        name:  'mat_app_proposals.other_manufacturer',
                                        title: 'form.field.other-manufacturer',
                                    },
                                    {
                                        data:  'opinion',
                                        name:  'mat_app_proposals.opinion',
                                        title: 'form.field.opinion',
                                    },
                                    {
                                        data:  'entity_name',
                                        name:  'entity_translations.name',
                                        title: 'form.field.entity',
                                    },
                                    {
                                        data:  'team_name',
                                        name:  'team_translations.name',
                                        title: 'form.field.team',
                                    },
                                    {
                                        data:  'created_by',
                                        name:  'user_entity_team_lots.identifier',
                                        title: 'form.field.created-by',
                                        type:  'userEntityTeamLot',
                                    },
                                    {
                                        data:            'created_at',
                                        name:            'mat_app_proposals.created_at',
                                        title:           'form.field.created-at',
                                        valueConverters: [
                                            {
                                                name: 'dateTimeFormat',
                                            },
                                        ],
                                    },
                                    {
                                        data:          'is_validated',
                                        name:          'is_validated',
                                        title:         'form.field.validated',
                                        type:          'custom-cell',
                                        fullDataModel: 'validator',
                                        viewModel:     PLATFORM.moduleName('modules/core/custom-listing-cells/checkmark/index'),
                                        orderable:     false,
                                        searchable:    false,
                                    },
                                    {
                                        data:       'files',
                                        name:       'files',
                                        title:      'form.title.files',
                                        type:       'custom-cell',
                                        icon:       'icon-attachment',
                                        show:       (row) => row.files && row.files.length > 0,
                                        action:     (row) => this.doShowFiles(row),
                                        viewModel:  PLATFORM.moduleName('modules/core/custom-listing-cells/action-icon/index'),
                                        orderable:  false,
                                        searchable: false,
                                    },
                                ],
        }
        ;
    }

    /**
     * Make list of allowed users
     *
     * @param row
     * @param column
     * @returns {string}
     */
    joinArrayInList(row, column) {
        let html = '<ul>';

        for (let item of row[column]) {
            html += '<li>';
            html += item;
            html += '</li>';
        }

        html += '</ul>';
        return html;
    }

    /**
     * Opens the application setting modal
     *
     */
    viewReclassify(type = 'manufacturer', selectedRows) {
        if (type !== 'manufacturer') type = 'supplier';
        this.dialogService
            .open({
                viewModel: (type === 'manufacturer') ? ReclassifyManufacturers : ReclassifySuppliers,
                model:     { selected_ids: selectedRows },
            })
            .whenClosed(async (response) => {
                if (!response.wasCancelled) {
                    // Fetch Data for Other Manufacturer or Supplier
                    const options        = await this.filterFormSchema['other_' + type].instance.fetchData();
                    const selectedValues = this.filterModel['other_' + type];

                    if (Array.isArray(selectedValues)) {
                        selectedValues.splice(
                            0,
                            selectedValues.length,
                            ...selectedValues.filter(value =>
                                options.some(option => option.id === value),
                            ),
                        );
                    }

                    this.filterFormSchema['other_' + type].instance.resolveDisplayText();
                    this.datatable.instance.updateStorageCriteria('other_' + type, this.filterModel['other_' + type]);
                    this.datatable.instance.reload();
                }
            });
    }

    /**
     * Executes a given action if it is selected at least one row
     *
     * @param action
     * @returns {*}
     */
    ensureSelectedRows(action) {
        if (!this.datatable.instance.selectedRows.length) {
            return this.dialogService.open({
                viewModel: InfoDialog,
                model:     {
                    body:  this.appContainer.i18n.tr('message.select-at-least-one-record'),
                    title: this.appContainer.i18n.tr('text.attention'),
                },
            });
        }

        action();
    }

    /**
     * Call promise then show response notice and reload datatable
     * @param call
     */
    callThenResponse(call) {
        call.then(response => {
            this.appContainer.notifier.responseNotice(response);
            this.datatable.instance.reload();
        });
    }

    /**
     * Closes a given record
     *
     * @param row
     */
    close(row) {
        this.repository
            .close(row.id)
            .then(response => {
                this.appContainer.notifier.responseNotice(response);
                this.datatable.instance.reload();
            });
    }

    /**
     * Reopens a given record
     *
     * @param row
     */
    reopen(row) {
        this.repository
            .reopen(row.id)
            .then(response => {
                this.appContainer.notifier.responseNotice(response);
                this.datatable.instance.reload();
            });
    }

    /**
     * Reopens a given record
     *
     * @param row
     */
    doShowFiles(row) {
        this.fileSettings.relatableModel = row;
        this.fileSettings.readonly       = row.is_validated;
        this.fileSettings.info           = row.is_validated
            ? this.info = this.infoAlertMessage('form.message.viewing-validated-record')
            : null;

        this.dialogService.open({ viewModel: ListFilesModal, model: this.fileSettings });
    }

    /**
     * Export ZIP
     *
     * @returns {*}
     */
    exportZip() {
        const filterModel = Object.assign(this.filterModel);

        filterModel.selectedRows = this.datatable.instance.selectedRows;

        return this.repository
            .generateZip(filterModel)
            .then(response => {
                response.status === true
                    ? this.appContainer.notifier.successNotice(this.i18N.tr('text.success-message.file-being-generated-please-wait'))
                    : this.appContainer.notifier.dangerNotice(response.message);
            });
    }
}
